import React from "react"
import styled, {keyframes} from "styled-components"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import {Section, SectionImageBkg, SectionPageTitle, SectionSubTitle, ExploreMores } from "../components/Section"
import {FormPrimary} from "../components/Form"
import StructuresTypes from "../components/StructuresTypes"
import StructureCarousel from "../components/StructureCarousel"
import StarRating from "../components/StarRating"
import ContactForm from "../components/ContactForm"
import BlueVector from "../images/blue-pattern.jpg"
import { MapIcon, PhoneIcon, WatchIcon } from "../components/Icons"
import DefaultButton from "../components/Button/DefaultButton"

const Ripple = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(19,131,193, 0.1),
                0 0 0 10px rgba(19,131,193, 0.1),
                0 0 0 20px rgba(19,131,193, 0.1);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(19,131,193, 0.1),
                0 0 0 20px rgba(19,131,193, 0.1),
                0 0 0 30px rgba(19,131,193, 0);
  }
`;

const HeroBackground = styled.div`    
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &:after, &:before{
    content: "";
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    clip-path: ellipse(100% calc(100% - 30px) at 50% 0);
    @media (min-width: 768px) {
      clip-path: ellipse(calc(100% - 15px) calc(100% + 180px) at 100% 50%);
    }
    @media (min-width: 992px) {
      clip-path: ellipse(calc(100% - 30px) calc(100% + 180px) at 100% 50%);
    }
  }

  &:before{
		background: transparent linear-gradient(270deg, #1383C1 0%, #006EAC 100%) 0% 0% no-repeat padding-box;		
	}
	&:after{
		background:${props => props.bgAfter};
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.15;
		background-position: center right;
	}	
`
const GridHero = styled.div`
  position: relative;
  @media (min-width: 768px) {
    display: grid;
    align-items:start;
    grid-template-columns:1fr 1fr;
  }
  @media (min-width: 1200px) {
    grid-template-columns:1.2fr 1fr;
  }
`

const GridHeroLeft = styled.div`
  position: relative;    
  padding: 30px 15px;
  @media (min-width: 992px) {
    padding: 40px 30px;
  }
  @media (min-width: 1200px) {
    padding: 60px 30px; 
  }
  @media (min-width: 1600px) {
    padding: 90px 30px;
  }
  @media (min-width: 1650px) {
    padding: 90px 30px 90px calc(50vw - 810px);  
  }
`
const GridHeroRight = styled.div`
  position: relative;    
  filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.4));    
  
  padding: 30px 15px 90px 15px;
  @media (min-width: 768px) {
    padding: 30px 30px 30px 60px;
    position: sticky;
    top: 64px;
  }
  @media (min-width: 992px) {
    padding: 30px 30px 40px 60px;
    height: calc(100vh - 64px);
  }
  @media (min-width: 1200px) {
    padding: 40px 30px 60px 60px;
    height: calc(100vh - 80px);
    top: 80px;
  }
  @media (min-width: 1600px) {
    padding: 120px 30px 170px 60px;
  }
  @media (min-width: 1650px) {
      padding: 120px calc(50vw - 810px) 170px 60px;
  }
`

const FormSection = styled(FormPrimary)`
  max-width:360px;
  width:100%;
  margin:0 auto;
  @media (min-width: 768px) {
    max-width:300px;
  }
  @media (min-width: 992px) {
    max-width:360px;
    margin-left: auto;
    margin-right: 0;
  }
  @media (min-width: 1200px) {
    max-width:440px;
  }
  @media (min-width: 1440px) {
    max-width:530px;
  }
  .form-action{
    justify-content: center;
    @media (min-width: 768px) {
      justify-content: flex-start;
    }
  }
`

const FormTitle = styled.div`
	color:#fff;
  margin-bottom:20px; 
  font-size:20px;
  line-height:30px; 
  font-weight:600;
  text-align:center;
  @media (min-width: 768px) {
    text-align:left;
  }
  @media (min-width: 1200px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 1600px) {
    font-size: 30px;
    line-height: 40px;
  }
`
const Sections = styled(Section)`
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
  padding:40px 0;
  @media (min-width: 992px) {
    padding:60px 0;
  }
  @media (min-width: 1600px) {
    padding:0;
  }

`
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items:center;
  justify-content:space-between;
  margin:0 -15px;
  @media (min-width: 992px) {
    margin:0 -30px;
  }
`
const GridLeft = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  @media (min-width: 992px) {
    padding:0 30px;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
`
const GridRight = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  @media (min-width: 992px) {
    padding:0 30px;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  @media (min-width: 1440px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`

const Review = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin:0 -15px;
` 
const ReviewPosted = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin:0 -9px;
    flex-direction:column;
    @media(min-width:992px){
        flex-direction:row;
    }
`
const ReviewRate = styled.div`
    padding:0 9px;
`
const PostedDate = styled.div`
    padding:0 9px;
    color:#999;
    font-size:14px;
    line-height:40px;
    font-weight:500;
`
const ViewList = styled.div`
  margin-bottom:30px;
  width: 100%;
`   
const ViewListItem = styled.div`
  + div{
    padding-top:30px;
    margin-top:30px;
    border-top:1px solid #ccc;
  }
  h3 {
    font-size: 32px;
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 0;
  }
  p{
    color:#000;
    font-weight:500;
    font-style:italic;
    font-size:20px;
    line-height:36px; 
    @media(min-width:1200px){
      font-size:22px;
      line-height:40px; 
    }     
    @media(min-width:1600px){
      font-size:28px;
      line-height:50px; 
    }     
  }
` 

const ContactInfo = styled.div`
  @media(min-width:1200px){
    margin-left:60px;
  }
  @media(min-width:1600px){
    margin-left: 16.666667%;
  }
  @media(min-width:1600px){
    margin-left: 33.333333%;
  }
  h1{
    margin-bottom:15px;
    @media(min-width:1200px){
      margin-bottom:30px;
    }
    @media(min-width:1600px){
      margin-bottom:50px;
    }
  }
`
const ContactItem = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin:0 -10px;
  @media(min-width:992px){
    margin:0 -20px;
  }
  + div{
    margin-top:30px;
    @media(min-width:992px){
      margin-top:40px;
    }
    @media(min-width:1600px){
      margin-top:80px;
    }
  }
`
const ContactIcon = styled.div`
  position:relative;
  
  padding:0 10px;
  @media(min-width:768px){
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  @media(min-width:992px){
    padding:0 20px;
  }
  .icn{
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;
    border-radius:50%;
    animation: ${Ripple} 0.7s linear infinite;
    > i{
      display:flex;
      justify-content: center;
      align-items: center;
      background:#1383C1;
      border-radius:50%;
      width:64px;    
      height:64px;
      @media(min-width:992px){
        width:74px;    
        height:74px;
      }
      @media(min-width:1200px){
        width:84px;    
        height:84px;
      }
      @media(min-width:1600px){
        width:94px;    
        height:94px;
      }
      svg{
        fill:#fff;
        height:26px;  
        @media(min-width:992px){
          height:auto;
        }
      }
    }
  }
`
const ContactDtl = styled.div`
  position:relative;
  padding:0 10px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  @media(min-width:992px){
    padding:0 20px;
  }
  
`
const ContactTitle = styled.div`
  color: #666;
  font-weight: 600;
  font-size:14px;
  line-height:24px;
  @media(min-width:992px){
    font-size:16px;
    line-height:26px;
    margin-bottom: 5px;
  }
  @media(min-width:1200px){
    font-size: 18px;
    line-height: 24px;
  }

`
const ContactContent = styled.div`
  color:#000;
  font-weight: 500;
  font-size:16px;
  line-height:26px;
  @media(min-width:992px){
    font-size:18px;
    line-height:28px;
  }
  @media(min-width:1200px){
    font-size:22px;
    line-height:34px;
  }
  @media(min-width:1600px){
    font-size:26px;
    line-height:36px; 
  }
  .phone{
    display:inline-block;
    color:#000;
    &:hover, &:focus{
      color: #1383C1;
    }
  }
  .email{
    display:inline-block;
    margin-bottom:0;
  }
`

const ListHour = styled.div`
  color:#000;
`
const ListHourItem = styled.div`  
  margin:0;
  span, small{
    display:inline-block;
    font-size:16px; 
    line-height: 24px; 
    @media(min-width:992px){
      font-size:18px; 
      line-height: 34px; 
    }
    @media(min-width:1440px){
      font-size:20px; 
    }
    
  }
  span{
    width:100%;
    max-width:110px;
    @media(min-width:992px){
      max-width:130px;
    }
    + small{
      margin-left:10px;
      @media(min-width:1440px){
        margin-left:20px;
      }
    }
  }
`

const ContactPage = ({ location, data }) => {
  const reviews = data.allContentfulReview.edges;
  return (
    <Layout location={location}>
      <Seo
        title={data.allContentfulPageData.edges[0].node.metaTitle}
        description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
      />
      <Section pt="0" pb="0" bgColor="#fff" className="section-about">
        <SectionImageBkg opacity="0.3"><StaticImage src="../images/contact-hero.jpg" alt="contact-hero" /></SectionImageBkg>
        <GridHero>
          <GridHeroLeft>
            <ContactInfo>
              <SectionPageTitle className="h2">CONTACT</SectionPageTitle>
              <ContactItem>
                <ContactIcon><span className="icn"><i><MapIcon /></i></span></ContactIcon>
                <ContactDtl>
                  <ContactTitle>Office Address</ContactTitle>
                  <ContactContent>631 SE Industrial Circle <br />Lake City, FL 32025</ContactContent>
                </ContactDtl>
              </ContactItem>

              <ContactItem>
                <ContactIcon><span className="icn"><i><PhoneIcon /></i></span></ContactIcon>
                <ContactDtl>
                  <ContactTitle>Contact & Email Info</ContactTitle>
                  <ContactContent>
                    <div><a href="tel:3869610006" className="phone">386-961-0006</a></div>
                    <div><a href="mailto:tubularbuildingsystems@gmail.com" className="email text-underline">tubularbuildingsystems@gmail.com</a></div>
                  </ContactContent>
                </ContactDtl>
              </ContactItem>
            
              <ContactItem>
                <ContactIcon><span className="icn"><i><WatchIcon /></i></span></ContactIcon>
                <ContactDtl>
                  <ContactTitle>Office Hours</ContactTitle>
                  <ContactContent>
                    <ListHour className="list-hour">
                      <ListHourItem><span>Monday-Friday </span> : <small>8 AM - 5 PM</small></ListHourItem>
                      <ListHourItem><span>Saturday</span> : <small>Closed</small></ListHourItem>
                      <ListHourItem><span>Sunday  </span> : <small>Closed</small></ListHourItem>
                    </ListHour>
                  </ContactContent>
                </ContactDtl>
              </ContactItem>
            </ContactInfo>

          </GridHeroLeft>
          <GridHeroRight>
            <HeroBackground bgAfter={`url(${BlueVector})`}>
              <FormSection>
                <FormTitle>Got Questions?  EMAIL OUR OFFICE…</FormTitle>
                <ContactForm location={location} />
              </FormSection>
            </HeroBackground>
          </GridHeroRight>
        </GridHero>
      </Section>

      <Section pt="120px" pb="120px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#fff" className="section-review">
        <div className="container">        
          <Review>
            <SectionSubTitle mb="15px">Customer Reviews</SectionSubTitle>
            <ViewList>
              {
                reviews.map((item, i) => (
                  <ViewListItem key={i}>
                    <h3>{item.node.reviewer}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.node.reviewText.childMarkdownRemark.html,
                      }}
                    />
                    <ReviewPosted className="review-post">
                      <ReviewRate>
                          <StarRating name="rating1" ragingId1={`${i}-1`} ragingId2={`${i}-2`} ragingId3={`${i}-3`} ragingId4={`${i}-4`} ragingId5={`${i}-5`} reviewStar={item.node.star} />
                      </ReviewRate>
                      <PostedDate className="post-date">{ item.node.createdAt }</PostedDate>
                    </ReviewPosted>
                  </ViewListItem>
                )
              )}
            </ViewList>
            <ExploreMores mr="auto" ml="auto">
              <Link to="/reviews">
                <DefaultButton primary text="More Reviews" />
              </Link>                           
            </ExploreMores>
          </Review>
        </div>
      </Section>

      <Sections pt="0" pb="0" bgColor="#fff" className="section-structure">	
        <div className="container">
          <Grid>
            <GridLeft>
              <SectionSubTitle mb="20px">GET IDEAS FROM THESE STRUCTURES</SectionSubTitle>
              <StructureCarousel data={data.allContentfulProductCategory.edges} />
            </GridLeft>
            <GridRight><StructuresTypes /></GridRight>
          </Grid>
        </div>
      </Sections>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageQuery{
    allContentfulProductCategory {
      edges {
        node {
          name
          cardImage {
            gatsbyImageData(quality: 90)
          }
        }
      }
    }
    allContentfulPageData(filter: {page: {eq: "Contact Us"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
    allContentfulReview(sort: {fields: createdAt, order: DESC}, limit: 2) {
      edges {
        node {
          reviewer
          star
          createdAt(formatString: "DD MMMM YYYY")
          reviewText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
