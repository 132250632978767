import React, { Component } from 'react'
import styled from "styled-components"
import {GatsbyImage} from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SlickSlider = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    margin:0 -10px 30px -10px;
    @media(min-width:992px){
        margin:0 -10px;
    }
    .slick-list{
        border-radius:10px;
    }
`
const SlickSliderFull = styled.div`
    position: relative;
    width: 100%;
    heigth: 600px;
    padding: 10px;
    @media(min-width:1200px){
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    /* .gatsby-image-wrapper {
        height: 335px !important;
    } */
`
const SlickItem = styled.div`
    outline: none;
    height: 100%;
    border-radius:20px;
    .gatsby-image-wrapper{
        border-radius:20px;
        overflow:hidden;
    }
    .gatsby-image-wrapper{
        height: 100%;

        img{
            border-radius:20px;
            overflow:hidden;
        }
    } 
   
`
const SlickSliderThumb = styled.div`
    position: relative;
    width: 100%;
    @media(min-width:992px){
        padding:0 10px;
    }
    @media(min-width:1200px){
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .gatsby-image-wrapper{
        img{
            border-radius:5px;
            overflow:hidden;
        }
    } 
    .slick-current{
        .gatsby-image-wrapper{
            border-color:#1383C1;
        }
    }
    
`
const SlickItemThumb = styled.div`
    outline: none;
    .gatsby-image-wrapper{
        display: inline-block;
        border: 4px solid transparent;
        width:100%;
        border-radius:10px;
        overflow:hidden;
        @media(min-width:1200px){
            max-width: 130px;
        }
    }
`


export default class AsNavFor extends Component {
    constructor(props) {
      super(props);
      this.state = {
        nav1: null,
        nav2: null
      };
    }
  
    componentDidMount() {
      this.setState({
        nav1: this.slider1,
        nav2: this.slider2
      });
    }
  
    render() {
        const settingsNav = ({
            slidesToShow: 5,
            slidesToScroll: 1,
            focusOnSelect:true,
            arrows:false,
            swipeToSlide:true,
            vertical:true,
            centerMode: true,
            
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                      swipeToSlide:false,
                      vertical:false,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        swipeToSlide:false,
                        vertical:false,
                        infinite: true,
                        centerMode: true,
                        centerPadding: '100px',
                        
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        swipeToSlide:false,
                        vertical:false,
                        infinite: true,
                        centerMode: true,
                        centerPadding: '130px',
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        swipeToSlide:false,
                        vertical:false,
                        infinite: true,
                        centerMode: true,
                        centerPadding: '20px',
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
        const settingsFor = ({
            slidesToShow: 1,
            slidesToScroll: 1,
            focusOnSelect:true,
            arrows:false,
            swipeToSlide:true,
            vertical:true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                      swipeToSlide:false,
                      vertical:false,
                    }
                }
              ]
        });

        const data = this.props.data
        return (
            <SlickSlider>
                <SlickSliderFull>           
                    <Slider               
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}      
                        {...settingsFor}
                    >
                        {data.map(( item, i) => (
                            <SlickItem key={i}><GatsbyImage image={item.node.cardImage.gatsbyImageData} alt={item.node.name} /></SlickItem>
                        ))}
                    </Slider>
                </SlickSliderFull>   

                <SlickSliderThumb>
                    <Slider
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        {...settingsNav}
                    >
                        {
                            data.map((item, i) => (
                                <SlickItemThumb><GatsbyImage image={item.node.cardImage.gatsbyImageData} alt={item.node.name} /></SlickItemThumb>
                            ))
                        }
                    </Slider>
                </SlickSliderThumb>
            </SlickSlider>
        );
    }
}
